import * as React from 'react'
import {getScheduleItemModalItem, isScheduleItemModalOpen, isFiltersModalOpen} from '../../selectors/modals'
import {ScheduleItem} from './schedule-item'
import {FiltersModal} from './filters-modal/filters'
import {ModalsProps} from '.'

export const Modals: React.FC<ModalsProps> = props => {
  const {modals} = props
  return (
    <>
      {isFiltersModalOpen(modals) && <FiltersModal {...props} />}
      {isScheduleItemModalOpen(modals) && <ScheduleItem {...props} item={getScheduleItemModalItem(modals)} />}
    </>
  )
}
