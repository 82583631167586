import React from 'react'
import {Dialog} from 'wix-ui-tpa/Dialog'
import {CheckboxGroup} from 'wix-ui-tpa/CheckboxGroup'
import {Checkbox} from 'wix-ui-tpa/Checkbox'
import {RadioButtonGroup} from 'wix-ui-tpa/RadioButtonGroup'
import {RadioButton} from 'wix-ui-tpa/RadioButton'
import {Button, PRIORITY} from 'wix-ui-tpa/Button'
import {Text, TYPOGRAPHY} from 'wix-ui-tpa/Text'
import {useForm, Controller} from 'react-hook-form/dist/index.ie11'
import classNames from 'classnames'
import {DH} from '../../../data-hooks'
import {classes as modalClasses} from '../modal.st.css'
import {classes} from './filters.st.css'
import {FilterFormData} from './interfaces'
import {FiltersModalProps} from './index'

export const FiltersModal: React.FC<FiltersModalProps> = ({
  tags,
  locations,
  selectedLocation,
  setFilter,
  toggleFiltersModal,
  resetFilter,
  t,
  host,
}) => {
  const defaultValues = {
    tags: Object.fromEntries(
      tags.map(({value: name, checked}) => {
        return [name, checked]
      }),
    ),
    location: selectedLocation,
  }

  const {handleSubmit, control} = useForm({
    defaultValues,
  })

  const onSubmit = async (data: FilterFormData) => {
    await setFilter({data})
    toggleFiltersModal()
  }

  const handleReset = async () => {
    await resetFilter()
    toggleFiltersModal()
  }

  const preview = host.viewMode === 'Preview'

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        className={classNames(modalClasses.root, {[modalClasses.previewMode]: preview})}
        isOpen
        data-hook={DH.MOBILE_FILTER_MODAL}
        onClose={() => toggleFiltersModal()}
        contentClassName={classes.modalContent}
      >
        <Text className={classes.title} typography={TYPOGRAPHY.smallTitle}>
          {t('schedulePlaces.mobileFilterTitle')}
        </Text>
        <div className={classes.formContainer}>
          <Controller
            control={control}
            name="location"
            render={({onChange, value, name}) => (
              <RadioButtonGroup
                name={name}
                label={t('schedulePlaces.mobileFilterLocations')}
                value={value}
                onChange={onChange}
              >
                {locations.map(({id, value: locationValue}) => (
                  <RadioButton
                    data-hook={DH.MOBILE_FILTER_LOCATION_RADIO}
                    className={classes.radio}
                    value={id}
                    label={locationValue}
                  />
                ))}
              </RadioButtonGroup>
            )}
          />
          <CheckboxGroup label={t('schedulePlaces.mobileFilterTags')} className={classes.checkboxGroup}>
            {tags.map(({value, title}) => (
              <Controller
                control={control}
                name={`tags[${value}]`}
                render={({onChange, value: tagValue, name}) => (
                  <Checkbox
                    data-hook={DH.MOBILE_FILTER_TAG_CHECKBOX}
                    name={name}
                    onChange={({checked}) => {
                      onChange(checked)
                    }}
                    label={title}
                    checked={tagValue}
                  />
                )}
              />
            ))}
          </CheckboxGroup>
        </div>
        <div className={classes.actions}>
          <Button
            upgrade
            data-hook={DH.MOBILE_FILTER_RESET}
            priority={PRIORITY.basicSecondary}
            className={classes.action}
            onClick={handleReset}
          >
            {t('schedulePlaces.mobileFilterReset')}
          </Button>
          <Button
            upgrade
            data-hook={DH.MOBILE_FILTER_SUBMIT}
            priority={PRIORITY.basic}
            className={classes.action}
            type="submit"
          >
            {t('schedulePlaces.mobileFilterSubmit')}
          </Button>
        </div>
      </Dialog>
    </form>
  )
}
