export const DH = {
  EVENT_TITLE: 'EVENT_TITLE',
  LOCATION: 'LOCATION',
  LOCATION_ICON: 'LOCATION_ICON',
  DESCRIPTION: 'DESCRIPTION',
  DESCRIPTION_BUTTON: 'DESCRIPTION_BUTTON',
  LOCATION_DROPDOWN: 'LOCATION_DROPDOWN',
  SCHEDULE_ITEM: 'SCHEDULE_ITEM',
  MOBILE_SCHEDULE_ITEM: 'MOBILE_SCHEDULE_ITEM',
  MOBILE_FILTER_MODAL: 'MOBILE_FILTER_MODAL',
  MOBILE_FILTER_LOCATION_RADIO: 'MOBILE_FILTER_LOCATION_RADIO',
  MOBILE_FILTER_TAG_CHECKBOX: 'MOBILE_FILTER_TAG_CHECKBOX',
  MOBILE_FILTER_SUBMIT: 'MOBILE_FILTER_SUBMIT',
  MOBILE_FILTER_RESET: 'MOBILE_FILTER_RESET',
  MOBILE_FILTER_BUTTON: 'MOBILE_FILTER_BUTTON',
}
