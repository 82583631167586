import React from 'react'
import classNames from 'classnames'
import {DateDivider} from './divider'
import {classes} from './schedule.st.css'
import {DesktopItem, MobileItem} from './item'
import {Time} from './time'
import {ScheduleProps} from '.'

export const Schedule = ({mobile, toggleScheduleItemModal, readDescriptionClicked, t, days}: ScheduleProps) => {
  const Item = mobile ? MobileItem : DesktopItem

  return (
    <div className={classNames(classes.agenda, {[classes.mobile]: mobile})}>
      {Object.entries(days).map(([key, day]) => (
        <div key={key} className={classes.day}>
          <Time className={classes.dayTitle} dateTime={day.title}>
            {day.title}
          </Time>
          <DateDivider />
          <ul>
            {day.items.map((item, index) => (
              <Item
                t={t}
                item={item}
                showDivider={index !== day.items.length - 1}
                key={index}
                toggleScheduleItemModal={toggleScheduleItemModal}
                readDescriptionClicked={readDescriptionClicked}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
